import { Component } from 'react';
import { Props, State } from './model';


export class ErrorBoundary extends Component<Props, State> {
    constructor (props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError () {
        return { hasError: true };
    }

    componentDidCatch (error: Error) {
        this.props.reportService.reportError(error);
    }

    render () {
        if (this.state.hasError) {
            return null;
        }

        return this.props.children;
    }
}
