import { browserLocalStorage } from 'entities/browser-storage';
import { BaseService } from './BaseService';


interface ICredentials {
    URL: string;
}

class WebAPIService extends BaseService {
    public saveToken (webToken: string) {
        browserLocalStorage.setItem('web_token', webToken);
    }

    public setCredentials (credentials: ICredentials): void {
        this._credentials = credentials;
    }

    protected get headers (): { headers: any } {
        return {
            headers: {
                Authorization: `Bearer ${ browserLocalStorage.getItem<string>('web_token') || '' }`,
            },
        };
    }

    public async updateRRP (body: { interested_IQOS?: number; interested_lil?: number }) {
        const route = '/users/updateRRP';
        return this.post(route, body);
    }
}

export const WebService = new WebAPIService();
