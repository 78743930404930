import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { attachLogger } from 'effector-logger/attach';
import { root } from 'effector-root';
import { i18n } from './shared/i18n';
import { Loader } from 'shared/ui/loader';
import { App } from 'app';


attachLogger(root, {
    console: 'enabled',
});

ReactDOM.render(
    <I18nextProvider i18n={ i18n }>
        <Suspense fallback={ <Loader/> }>
            <App/>
        </Suspense>
    </I18nextProvider>,
    document.getElementById('root')
);
