import React from 'react';


export const Loader = () => {
    return (
        <div id='loader'>
            <img src="/assets/img/logo.svg" alt="logo"/>
        </div>
    );
};
