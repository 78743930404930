import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ErrorBoundary } from 'shared/hocs/error-boundary';
import { Loadable } from 'shared/hocs/loadable';
import { reportService } from 'entities';
import { GoodLuckAppLoadable } from 'applications/good-luck';
import { Loader } from 'shared/ui/loader';
import { GOOD_LUCK_BASE_URL } from 'shared/constants';


const marlboroId        = process.env.REACT_APP_MARLBORO_ID || '';
const summerQuizId      = process.env.REACT_APP_SUMMER_QUIZ_ID || '';
const testQuizId        = process.env.REACT_APP_TEST_QUIZ_ID || '';
const parliamentId      = process.env.REACT_APP_PARLIAMENT_ID || '';
const interactiveGameId = process.env.REACT_APP_INTERACTIVE_GAME_ID || '';
const countriesId       = process.env.REACT_APP_COUNTRIES_ID || '';
const archetypeId       = process.env.REACT_APP_ARCHETYPE_ID || '';
const iqosId            = process.env.REACT_APP_IQOS_ID || '';
const winterShuffleId   = process.env.REACT_APP_WINTER_SHUFFLE_ID || '';
const winterShuffleMBId = process.env.REACT_APP_WINTER_SHUFFLE_MB_ID || '';

const loyaltyIdParliament = process.env.REACT_APP_LOYALTY_PARLIAMENT_ID || '';
const loyaltyIdLM         = process.env.REACT_APP_LOYALTY_LM_ID || '';
const loyaltyIdMPM        = process.env.REACT_APP_LOYALTY_MPM_ID || '';
const loyaltyIdMarlboro   = process.env.REACT_APP_LOYALTY_MARLBORO_ID || '';
const goodLuckId          = process.env.REACT_APP_GOOD_LUCK_ID || '';


const TestQuiz = Loadable({
    loader: (): any => import(/* webpackChunkName: "TestQuiz" */ 'Games/TestQuiz').then((res) => res.TestQuiz),
    loading: <Loader/>,
});

const SummerQuiz = Loadable({
    loader: (): any => import(/* webpackChunkName: "SummerQuiz" */ 'Games/SummerQuiz').then((res) => res.SummerQuiz),
    loading: <Loader/>,
});

const Marlboro = Loadable({
    loader: (): any => import(/* webpackChunkName: "Marlboro" */ 'Games/Marlboro').then((res) => res.Marlboro),
    loading: <Loader/>,
});

const Parliament = Loadable({
    loader: (): any => import(/* webpackChunkName: "Parliament" */ 'Games/Parliament').then((res) => res.Parliament),
    loading: <Loader/>,
});

const SurveyInteractiveGame = Loadable({
    loader: (): any => import(/* webpackChunkName: "SurveyInteractiveGame" */ 'Games/SurveyInteractiveGame').then(
        (res) => res.SurveyInteractiveGame
    ),
    loading: <Loader/>,
});

const Countries = Loadable({
    loader: (): any => import(/* webpackChunkName: "Countries" */ 'Games/Countries').then((res) => res.Countries),
    loading: <Loader/>,
});

const Archetype = Loadable({
    loader: (): any => import(/* webpackChunkName: "Archetype" */ 'Games/Archetype').then((res) => res.Archetype),
    loading: <Loader/>,
});

const Iqos = Loadable({
    loader: (): any => import(/* webpackChunkName: "Iqos" */ 'Games/Iqos').then((res) => res.Iqos),
    loading: <Loader/>,
});

const Survey = Loadable({
    loader: (): any => import(/* webpackChunkName: "Survey" */ 'Games/Survey').then((res) => res.Survey),
    loading: <Loader/>,
});

const SurveyConstructor = Loadable({
    loader: (): any => import(/* webpackChunkName: "Survey" */ 'Games/SurveyConstructor').then(
        (res) => res.SurveyConstructor
    ),
    loading: <Loader/>,
});

const SurveyConstructorIqos = Loadable({
    loader: (): any => import(/* webpackChunkName: "SurveyConstructorIqos" */ 'Games/SurveyConstructorIqos').then(
        (res) => res.SurveyConstructorIqos
    ),
    loading: <Loader/>,
});

const SurveyLoyalty = Loadable({
    loader: (): any => import(/* webpackChunkName: "SurveyLoyalty" */ 'Games/SurveyLoyalty').then(
        (res) => res.SurveyLoyalty
    ),
    loading: <Loader/>,
});

const WinterShuffle = Loadable({
    loader: (): any => import(/* webpackChunkName: "WinterShuffle" */ 'Games/WinterShuffle').then(
        (res) => res.WinterShuffle
    ),
    loading: <Loader/>,
});

const WSMRL = Loadable({
    loader: (): any => import(/* webpackChunkName: "WSMRL" */ 'Games/WSMRL').then((res) => res.WSMRL),
    loading: <Loader/>,
});

const LoyaltyInfo = Loadable({
    loader: (): any => import(/* webpackChunkName: "TestQuiz" */ 'LoyaltyInfo').then((res) => res.LoyaltyInfo),
    loading: <Loader/>,
});

export const Router = () => (
    <ErrorBoundary reportService={ reportService }>
        <BrowserRouter>
            <Switch>
                <Route path="/brand/:name" component={ LoyaltyInfo }/>
                <Route path={ `/constructor/${ goodLuckId }` } component={ GoodLuckAppLoadable }/>
                <Route path={ `/constructor/${ loyaltyIdParliament }` } component={ SurveyLoyalty }/>
                <Route path={ `/constructor/${ loyaltyIdLM }` } component={ SurveyLoyalty }/>
                <Route path={ `/constructor/${ loyaltyIdMPM }` } component={ SurveyLoyalty }/>
                <Route path={ `/constructor/${ loyaltyIdMarlboro }` } component={ SurveyLoyalty }/>
                <Route path={ '/constructor-iqos/:id' } component={ SurveyConstructorIqos }/>
                <Route path={ '/constructor/:id' } component={ SurveyConstructor }/>
                {/* // redirect telegram start */ }
                <Route path={ `/${ testQuizId }` } component={ TestQuiz }/>
                <Route path={ `/${ countriesId }` } component={ Countries }/>
                <Route path={ `/${ summerQuizId }` } component={ SummerQuiz }/>
                {/* // redirect telegram finish */ }
                <Route path={ `/${ marlboroId }` } component={ Marlboro }/>
                <Route path={ `/${ interactiveGameId }` } component={ SurveyInteractiveGame }/>
                <Route path={ `/${ parliamentId }` } component={ Parliament }/>

                <Route path={ `/${ archetypeId }` } component={ Archetype }/>
                <Route path={ `/${ iqosId }` } component={ Iqos }/>
                <Route path={ `/${ winterShuffleMBId }` } component={ WSMRL }/>
                <Route path={ `/${ winterShuffleId }` } component={ WinterShuffle }/>
                <Route path={ `/${ loyaltyIdParliament }` } component={ SurveyLoyalty }/>
                <Route path={ `/${ loyaltyIdLM }` } component={ SurveyLoyalty }/>
                <Route path={ `/${ loyaltyIdMPM }` } component={ SurveyLoyalty }/>
                <Route path={ `/${ loyaltyIdMarlboro }` } component={ SurveyLoyalty }/>
                <Route path={ `/${ goodLuckId }` } component={ GoodLuckAppLoadable }/>
                <Route path={ `${ GOOD_LUCK_BASE_URL }/:id` } component={ GoodLuckAppLoadable }/>
                <Route component={ Survey }/>
                {/* <Route component={SurveyConstructorIqos} /> */ }
            </Switch>
        </BrowserRouter>
    </ErrorBoundary>
);
