/* eslint-disable no-unused-vars */
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import { browserLocalStorage } from 'entities/browser-storage';


export enum LocaleCode {
    ru = 'ru',
    kz = 'kz',
}

export const i18n = i18next.createInstance();
const lng = browserLocalStorage.getItem<LocaleCode>('lang') || LocaleCode.ru;

i18n
    .use(HttpApi)
    .use(initReactI18next)
    .init({
        lng,
        fallbackLng: lng,
        debug      : false,
        ns         : [],
        defaultNS  : '',
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: `${ process.env.REACT_APP_TRANSLATIONS_URL }{{lng}}/?namespace={{ns}}`,
        },
        react: {
            useSuspense: true,
        },
    });

const getLocale = () => (i18n.language || lng);
const setHtmlLang = (lang: LocaleCode) => {
    const html = document.querySelector('html');
    html && (html.lang = lang);
};
export const setLocale = (locale: LocaleCode, force = false) => {
    if (!force && locale === getLocale()) return;
    i18n.changeLanguage(locale);
    setHtmlLang(locale);
    browserLocalStorage.setItem('lang', locale);
};

setLocale(lng, true);
