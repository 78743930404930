import { browserLocalStorage } from 'entities/browser-storage';
import { BaseService } from './BaseService';


class AuthAPIService extends BaseService {
    public saveToken (gameId: string, token: string, redirectUrl?: string) {
        browserLocalStorage.setItem('game_token', token);
        if (redirectUrl) {
            browserLocalStorage.setItem('redirect_url', redirectUrl);
        }

        browserLocalStorage.setItem('user_id', gameId);
    }

    public saveOnlyTokenID (gameId: string, token: string) {
        browserLocalStorage.setItem('game_token', token);
        browserLocalStorage.setItem('user_id', gameId);
    }

    public removeToken () {
        browserLocalStorage.removeItem('game_token');
        browserLocalStorage.removeItem('redirect_url');
        browserLocalStorage.removeItem('user_id');
    }
}

export const AuthService = new AuthAPIService();
