import React, { lazy, Suspense } from 'react';
import { LoadableTypes } from './model';


// eslint-disable-next-line no-unused-vars
export const Loadable: <T>(component: LoadableTypes<T>) => (props: T) => JSX.Element = ({
    loader,
    loading,
}) => {
    const Loader = lazy(() =>
        loader().then((res: any) => ({
            default: res,
        }))
    );

    return (props) => (
        <Suspense fallback={ loading }>
            <Loader { ...props } />
        </Suspense>
    );
};
