import { BaseService } from './BaseService';


class GameAPIService extends BaseService {
    public async startGame () {
        const route = '/start';
        return this.get(route);
    }

    public async updateGameLevel (level: number, status: boolean | null) {
        const route = '/levels/update';
        const data  = {
            level_number: level,
            status,
        };
        return this.put(route, data);
    }

    public async getGameFlows () {
        const route = '';
        return this.get(route);
    }

    public async restartGame () {
        const route = '/restart';
        return this.get(route);
    }

    public async finishGame () {
        const route = '/finish';
        return this.get(route);
    }

    public async uploadFile (code: string) {
        const route = '/upload64';
        return this.post(route, { code });
    }
}

export const GameService = new GameAPIService();
